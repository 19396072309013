<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div>
    <Overplay v-if="isShow" />
    <!-- <Overplay v-if="isShow" /> -->
    <b-form-group label="Product name:" label-cols-md="12">
      <validation-provider
        #default="{ errors }"
        name="Product name"
        rules="required"
      >
        <b-form-input
          v-model="value.name"
          :state="errors.length > 0 ? false : null"
          :placeholder="$t('Product name')"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group label="Product description:" label-cols-md="12">
      <div style="max-height: 400px; overflow: scroll;">
        <quill-editor
          id="content"
          ref="qEdit"
          :content="value.description"
          :options="snowOption"
          style="min-height: 180px;"
          @change="onEditorChange($event)"
        />
        <input
          id="getFile"
          type="file"
          @change="uploadFunction($event)"
        >
        <span class="count-textarea-length">
          {{ product_description_length }}/1000
        </span>
      </div>
    </b-form-group>
    <b-form-group label="SKU:" label-cols-md="12">
      <validation-provider #default="{ errors }" name="SKU" rules="required">
        <b-form-input
          v-model="value.item_sku"
          :state="errors.length > 0 ? false : null"
          placeholder=""
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group label="Category:" label-cols-md="12">
      <v-select
        v-if="categoryOptions"
        v-model="value.category_id"
        label="category_name"
        :reduce="x => x.category_id"
        :options="categoryOptions"
      />
    </b-form-group>
    <b-form-group label="Product condition:" label-cols-md="12">
      <v-select
        v-if="conditionOptions"
        v-model="value.condition"
        label="name"
        :options="conditionOptions"
      />
    </b-form-group>
    <b-form-group label="Price:" label-cols-md="12">
      <validation-provider #default="{ errors }" name="Price" rules="required">
        <b-form-input
          v-model="value.price"
          type="number"
          :state="errors.length > 0 ? false : null"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group label="Stock:" label-cols-md="12">
      <validation-provider #default="{ errors }" name="Stock" rules="required">
        <b-form-input
          v-model="value.stock"
          type="number"
          :state="errors.length > 0 ? false : null"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */

import { ValidationProvider } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'
import vSelect from 'vue-select'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    quillEditor,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isShow: false,
      required,
      categoryOptions: [],
      conditionOptions: ['News', 'Used'],
      editorOption: {
        placeholder: '',
      },
      snowOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ['clean'],
              ['image'],
            ],
            handlers: {
              image: () => {
                document.getElementById('getFile').click()
              },
            },
          },
        },
      },
      product_description_length: 0,
    }
  },
  mounted() {
    this.loadCategoryList()
  },
  methods: {
    onEditorChange({ html }) {
      this.value.description = html
      this.product_description_length = html.length
    },
    async loadCategoryList() {
      this.isShow = true
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_categories?site_id=${siteId}`,
        )
        if (result.status === 200) {
          if (result.data.status) {
            this.categoryOptions = []
            const res = result.data.data
            this.categoryOptions = res.filter(x => !x.has_children)
            this.isShow = false
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(result.data.error, ',')),
              },
            })
            this.isShow = false
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    uploadFunction(e) {
      const vm = this
      const image = e.target.files[0]
      if (image) {
        if (image.size > 5000000) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: 'Picture is too large! < 5MB',
            },
          })
        } else {
          const reader = new FileReader()
          reader.readAsDataURL(image)
          reader.onload = ev => {
            vm.postImge(ev.target.result)
          }
        }
      }
    },
    async postImge(image) {
      const range = this.$refs.qEdit.quill.getSelection()
      const params = {
        site_id: JSON.parse(localStorage.getItem('siteID')).id,
        type_table: 'blog',
        type: 'desktop',
        avatar: image,
        table_field: 'ckeditor',
        in_use: 1,
      }
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_IMG_URL}/images_only`,
        params,
      )
      this.$refs.qEdit.quill.insertEmbed(
        range.index,
        'image',
        res.data.data.image,
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
<style lang="scss" scoped>
#getFile {
  display: none;
}
</style>
