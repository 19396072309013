<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div>
    <Overplay v-if="isShow" />
    <b-form-group
      v-for="(item,index) in value.attributes"
      :key="index"
      :label="`Variation ${index+1}:`"
      label-cols-md="12"
    >
      <div v-if="attributes && attributes.length > 0 && attributes[index] " class="bg-gray">
        <b-form-group label="Name" label-cols-md="12">
          <v-select
            v-if="attributes[index] && attributes[index].attributeList && attributes[index].attributeList.length > 0"
            v-model="ware_house_temp[index]"
            :disabled="attributes[index].attributeList && attributes[index].attributeList.length > 0 ? false : true"
            label="name_type"
            :options="attributes[index].attributeList"
            placeholder="Enter Variation Name, eg: Color"
            @input="handleChooseVariation(index)"
          />
        </b-form-group>
        <b-form-group label="Options" label-cols-md="12">
          <div
            class="d-flex align-items-center mb-1"
          >
            <v-select
              v-model="attributes[index].attributeOptionsValue"
              :disabled="ware_house_temp[index] ? false : true"
              label="name"
              :options="attributes[index].attributeOptions"
              class="w-100"
              @input="handleChooseOption(index)"
            />
          </div>
        </b-form-group>
      </div>
    </b-form-group>

    <!-- <b-form-group
      :label="$t('Variation')+ ` 2:`"
      label-cols-md="12"
    >
      <div class="bg-gray">
        <b-form-group label="Name" label-cols-md="12">
          <v-select
            v-model="ware_house_temp[1]"
            :disabled="attributeList2 && attributeList2.length > 0 ? false : true"
            label="name_type"
            :options="attributeList2"
            placeholder="Enter Variation Name, eg: Color"
            @input="handleChooseVariation(2)"
          />
        </b-form-group>
        <b-form-group label="Options" label-cols-md="12">
          <div class="d-flex align-items-center mb-1">
            <v-select
              v-model="attributeOptionsValue2"
              :disabled="ware_house_temp[1] ? false : true"
              label="name"
              :options="attributeOptions2"
              class="w-100"
              @input="handleChooseOption(2)"
            />
          </div>
        </b-form-group>
      </div>
    </b-form-group> -->
  </div>
</template>

<script>
/* eslint-disable object-shorthand */
/* eslint-disable array-callback-return */

import {
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isShow: false,
      attributeList: [],
      attributes: [],
      // attributeList1: [],
      // attributeList2: [],
      // attributeOptions1: [],
      // attributeOptionsValue1: null,
      // attributeOptions2: [],
      // attributeOptionsValue2: null,
      ware_house_temp: [],

    }
  },
  watch: {
    'value.category_id'(val) {
      if (val) {
        this.loadAttributeList(val)
        this.attributes = []
        this.value.attributes = []
      }
    },
  },
  methods: {
    async handleChooseVariation(index) {
      this.attributes[index].attributeOptions = this.ware_house_temp[index].list
      this.attributes[index].attributeOptionsValue = null
      this.attributes.map((val, idx) => {
        if (index !== idx) {
          this.attributes[idx].attributeList = this.attributeList.filter(x => x.name_type !== this.ware_house_temp[index].name_type)
        }
      })
    },
    async handleChooseOption(index) {
      this.value.attributes[index].attributes_id = this.ware_house_temp[index].id
      this.value.attributes[index].value = this.attributes[index].attributeOptionsValue.name
    },
    async loadAttributeList(categoryID) {
      this.isShow = true
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_attributes?site_id=${siteId}&category_id=${categoryID}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.value.attributes = []
            this.attributes = []
            this.attributeList = []
            res.data.data.map(val => {
              if (val.is_mandatory) {
                const item = {
                  id: val.attribute_id,
                  name_type: val.attribute_name,
                  list: val.options.map((x, idx) => ({
                    code_color: null,
                    id: idx,
                    name: x,
                  })),
                }
                this.value.attributes.push({ attributes_id: null, value: null })
                this.attributes.push({
                  attributeList: [],
                  attributeOptionsValue: null,
                  attributeOptions: [],
                })
                this.attributeList.push(item)
              }
            })
            this.attributes.map((val, index) => {
              this.attributes[index].attributeList = this.attributeList
            })
            this.isShow = false
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
            this.isShow = false
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
        this.isShow = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
button svg {
  margin-right: 0 !important;
}
</style>
