var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isShow)?_c('Overplay'):_vm._e(),_c('b-form-group',{attrs:{"label":"Product name:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Product name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Product name')},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Product description:","label-cols-md":"12"}},[_c('div',{staticStyle:{"max-height":"400px","overflow":"scroll"}},[_c('quill-editor',{ref:"qEdit",staticStyle:{"min-height":"180px"},attrs:{"id":"content","content":_vm.value.description,"options":_vm.snowOption},on:{"change":function($event){return _vm.onEditorChange($event)}}}),_c('input',{attrs:{"id":"getFile","type":"file"},on:{"change":function($event){return _vm.uploadFunction($event)}}}),_c('span',{staticClass:"count-textarea-length"},[_vm._v(" "+_vm._s(_vm.product_description_length)+"/1000 ")])],1)]),_c('b-form-group',{attrs:{"label":"SKU:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"SKU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.value.item_sku),callback:function ($$v) {_vm.$set(_vm.value, "item_sku", $$v)},expression:"value.item_sku"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Category:","label-cols-md":"12"}},[(_vm.categoryOptions)?_c('v-select',{attrs:{"label":"category_name","reduce":function (x) { return x.category_id; },"options":_vm.categoryOptions},model:{value:(_vm.value.category_id),callback:function ($$v) {_vm.$set(_vm.value, "category_id", $$v)},expression:"value.category_id"}}):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Product condition:","label-cols-md":"12"}},[(_vm.conditionOptions)?_c('v-select',{attrs:{"label":"name","options":_vm.conditionOptions},model:{value:(_vm.value.condition),callback:function ($$v) {_vm.$set(_vm.value, "condition", $$v)},expression:"value.condition"}}):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Price:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.value.price),callback:function ($$v) {_vm.$set(_vm.value, "price", $$v)},expression:"value.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Stock:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Stock","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.value.stock),callback:function ($$v) {_vm.$set(_vm.value, "stock", $$v)},expression:"value.stock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }