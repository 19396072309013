<template>
  <div>
    <Overplay v-if="isShow" />
    <b-form-group
      label="Weight:"
      label-cols-md="12"
    >
      <validation-provider
        #default="{ errors }"
        name="Weight"
        rules="required"
      >
        <b-input-group
          append=" | kg"
          class="input-group-merge"
        >
          <b-form-input
            v-model="value.weight"
            :state="errors.length > 0 ? false : null"
            type="number"
            placeholder=""
          />
        </b-input-group>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group
      label="Parcel Size:"
      label-cols-md="12"
    >
      <b-row>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            name="Length"
            rules="required"
          >
            <b-input-group
              prepend="L | "
              append=" | cm"
              class="input-group-merge"
            >
              <b-form-input
                v-model="value.package_length"
                :state="errors.length > 0 ? false : null"
                type="number"
                placeholder=""
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            name="Width"
            rules="required"
          >
            <b-input-group
              prepend="W | "
              append=" | cm"
              class="input-group-merge"
            >
              <b-form-input
                v-model="value.package_width"
                :state="errors.length > 0 ? false : null"
                type="number"
                placeholder=""
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="4">
          <validation-provider
            #default="{ errors }"
            name="Height"
            rules="required"
          >
            <b-input-group
              prepend="H | "
              append=" | cm"
              class="input-group-merge"
            >
              <b-form-input
                v-model="value.package_height"
                :state="errors.length > 0 ? false : null"
                type="number"
                placeholder=""
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
    </b-form-group>
    <b-form-group
      label="Shipping:"
      label-cols-md="12"
    >
      Please choose the shipping channel
      <div class="shipping-box px-2 py-1 mt-2">
        <div
          v-for="(item,index) in logisticsList"
          :key="index"
          class="d-flex align-items-center my-1"
        >
          <div class="flex-grow-1">
            <strong>{{ item.logistic_name }}</strong> (Tối đa {{ unitFormat( item.weight_limits.item_max_weight ) }}kg)
          </div>
          <!-- <span class="mr-2">₫14.400</span> -->
          <b-form-checkbox
            v-model="value.logistics[index].enabled"
            checked="true"
            class="custom-control-success"
            name="check-button"
            switch
          />
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
/* eslint-disable array-callback-return */

import { ValidationProvider } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'

export default {
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BFormCheckbox,
  },
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isShow: false,
      required,
      logisticsList: [],
    }
  },
  mounted() {
    this.loadLogisitics()
  },
  methods: {
    async loadLogisitics() {
      this.isShow = true
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_logistics?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            const temp = []
            res.data.data.map(val => {
              // this.value.logistics.push({
              //   logistic_id: val.logistic_id,
              //   enabled: val.enabled,
              // })
              if (val.mask_channel_id === 0) {
                temp.push({ ...val })
                this.value.logistics.push({
                  logistic_id: val.logistic_id,
                  enabled: val.enabled,
                })
              }
            })
            this.logisticsList = temp
            this.isShow = false
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
            this.isShow = false
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
<style scoped>
.shipping-box {
  border-radius: 4px;
  border: 1px solid black
}
</style>
