<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <validation-observer ref="simpleRules">
    <Overplay v-if="isShow" />
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="information" :title="$t('Basic Information')">
            <BasicInformation
              id="BI"
              v-model="model"
            />
          </Fieldset>
          <Fieldset id="salesinformation" title="Attributes">
            <Variation v-model="model" />
          </Fieldset>
          <!-- <Fieldset id="salesinformation" :title="$t('Sales Information')">
            <SalesInformation id="SI" v-model="model" />
          </Fieldset> -->
          <!-- <Fieldset id="mediamanagement" :title="$t('Media Management')">
            <MediaManagement id="MM" v-model="model" />
          </Fieldset> -->
          <Fieldset id="shippingdetail" title="Shipping Detail">
            <ShippingDetail id="SD" v-model="model" />
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="status" :title="$t('Status')">
              <b-form-group label="Status:" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <b-form-group label="Item dangerous:" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.item_dangerous"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <hr>
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="ml-2"
                  @click.prevent="validationForm"
                >
                  Create on Shopee
                </b-button>
              </div>
            </Fieldset>
            <Fieldset id="avatar" :title="$t('Avatar')">
              <FeatureImage
                :avatar="model.images"
                @previewFiles="previewFiles"
              />
            </Fieldset>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
/* eslint-disable array-callback-return */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unreachable */

import VueScrollactive from 'vue-scrollactive'
import {
  // BLink,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormCheckbox,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import Vue from 'vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Variation from './sync-product-components/components/Variation.vue'
import BasicInformation from './sync-product-components/BasicInformation.vue'
// import SalesInformation from './sync-product-components/SalesInformation.vue'
// import MediaManagement from './sync-product-components/MediaManagement.vue'
import ShippingDetail from './sync-product-components/ShippingDetail.vue'

Vue.use(VueScrollactive)

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BasicInformation,
    // SalesInformation,
    // MediaManagement,
    BButton,
    ValidationObserver,
    BFormCheckbox,
    BFormGroup,
    ShippingDetail,
    Variation,
  },
  mixins: [general],
  data() {
    return {
      isShow: false,
      model: {
        site_id: null,
        id: null,
        category_id: null,
        name: null,
        description: null,
        price: null,
        stock: null,
        item_sku: null,
        images: null, // [ { "url": "https://phukien239.vn/images/news/20160222/wifi.jpg" } ]
        attributes: [],
        logistics: [], // [ { "logistic_id": 50018, "enabled": true } ]
        weight: null,
        package_length: null,
        package_width: null,
        package_height: null,
        // days_to_ship: null,
        condition: null,
        status: 'NORMAL',
        item_dangerous: null,

        // stock: 200,
        // weight: 0.2,
        // package_length: 30,
        // package_width: 23,
        // package_height: 25,
        days_to_ship: 3,
      },
    }
  },
  async mounted() {
    this.loadDetail()
  },
  methods: {
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        this.isShow = true
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/product/${this.$route.params.id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          this.isShow = false
          if (res.data.status) {
            this.model = {
              ...this.model,
              images: res.data.data.avatar,
              name: res.data.data.name,
              item_sku: res.data.data.sku,
              description: res.data.data.content,
              price: res.data.data.price,
            }
          } else {
            this.model.isShow = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.model.isShow = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async validationForm() {
      this.isShow = true
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              product_id: this.model.id,
              category_id: this.model.category_id,
              name: this.model.name,
              description: this.model.description,
              price: this.model.price,
              stock: this.model.stock,
              item_sku: this.model.item_sku,
              images: JSON.stringify([{
                url: this.model.images,
              }]), // [ { "url": "https://phukien239.vn/images/news/20160222/wifi.jpg" } ]
              attributes: null,
              logistics: JSON.stringify(this.model.logistics), // [ { "logistic_id": 50018, "enabled": true } ]
              weight: this.model.weight,
              package_length: this.model.package_length,
              package_width: this.model.package_width,
              package_height: this.model.package_height,
              days_to_ship: this.model.days_to_ship,
              condition: this.model.condition,
              status: this.model.status,
              item_dangerous: Number(this.model.item_dangerous ? 1 : 0),
            }
            // Find if attribute is null to remove from array
            const findIndex = this.model.attributes.findIndex(x => x.attributes_id === null)
            if (findIndex >= 0) {
              this.model.attributes.splice(findIndex, 1)
            }
            params.attributes = JSON.stringify(this.model.attributes)
            // JSON.stringify()
            // if (this.model.ware_house && this.model.ware_house.length > 0) {
            //   this.model.ware_house.map(item => {
            //     params.warehouse.push(JSON.stringify(item))
            //   })
            // } else {
            //   params.warehouse.push(
            //     JSON.stringify({
            //       attributes_id: null,
            //       value: null,
            //     }),
            //   )
            // }
            await this.handleCreate(params)
            this.isShow = false
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
            this.isShow = false
          }
        } else {
          this.isShow = false
        }
      })
    },
    async handleCreate(params) {
      this.isShow = true
      const res = await Request.post(
        this.$http,
        `${process.env.VUE_APP_API_URL}/shopee/add_item`,
        params,
      )
      if (res.status === 200) {
        if (res.data.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Congratulation !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Create Product on Shopee success',
            },
          })
          this.$router.push('/sales-channel/shopee')
          this.isShow = false
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
          this.isShow = false
        }
      }
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.images = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>
<style lang="scss" scoped>
.right-fixed-menu {
  width: 100%;
  max-width: 350px;
  right: 30px;
  a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    margin: 8px 0;
    position: relative;
    color: var(--secondary);
    &:before {
      content: '';
      height: 100%;
      width: 1.8px;
      background: var(--secondary);
      position: absolute;
      left: 0;
      top: 0;
    }
    &.active {
      color: var(--primary);
      font-weight: bold;
      &:before {
        width: 2px;
        background: var(--primary);
      }
    }
  }
}
</style>
