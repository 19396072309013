var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isShow)?_c('Overplay'):_vm._e(),_c('b-form-group',{attrs:{"label":"Weight:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":" | kg"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","placeholder":""},model:{value:(_vm.value.weight),callback:function ($$v) {_vm.$set(_vm.value, "weight", $$v)},expression:"value.weight"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Parcel Size:","label-cols-md":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Length","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"L | ","append":" | cm"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","placeholder":""},model:{value:(_vm.value.package_length),callback:function ($$v) {_vm.$set(_vm.value, "package_length", $$v)},expression:"value.package_length"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Width","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"W | ","append":" | cm"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","placeholder":""},model:{value:(_vm.value.package_width),callback:function ($$v) {_vm.$set(_vm.value, "package_width", $$v)},expression:"value.package_width"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Height","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"prepend":"H | ","append":" | cm"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","placeholder":""},model:{value:(_vm.value.package_height),callback:function ($$v) {_vm.$set(_vm.value, "package_height", $$v)},expression:"value.package_height"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Shipping:","label-cols-md":"12"}},[_vm._v(" Please choose the shipping channel "),_c('div',{staticClass:"shipping-box px-2 py-1 mt-2"},_vm._l((_vm.logisticsList),function(item,index){return _c('div',{key:index,staticClass:"d-flex align-items-center my-1"},[_c('div',{staticClass:"flex-grow-1"},[_c('strong',[_vm._v(_vm._s(item.logistic_name))]),_vm._v(" (Tối đa "+_vm._s(_vm.unitFormat( item.weight_limits.item_max_weight ))+"kg) ")]),_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":"true","name":"check-button","switch":""},model:{value:(_vm.value.logistics[index].enabled),callback:function ($$v) {_vm.$set(_vm.value.logistics[index], "enabled", $$v)},expression:"value.logistics[index].enabled"}})],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }